export function summaryTableReducer(state, action) {
  switch (action.type) {
    case "GRAPHS_FETCH_INIT":
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    case "GRAPHS_FETCH_INITIALIZE":
      return {
        ...state,
        isLoading: false,
        isError: false,
        data: action.payload,
      };
    case "GRAPHS_FETCH_SUCCESS":
      return {
        ...state,
        isLoading: false,
        isError: false,
        data: action.payload,
      };
    case "GRAPHS_FETCH_FAILUE":
      return {
        ...state,
        isLoading: false,
        isError: true,
      };
    default:
      return {
        ...state,
        isLoading: false,
        isError: true,
      };
  }
}

export function tableReducer(state, action) {
  switch (action.type) {
    case "GRAPHS_FETCH_INIT":
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    case "GRAPHS_FETCH_SUCCESS":
      const tickets = action.payload.anomalies;
      console.log("ticketsData", tickets.length);
      const openTicketCount = action.payload.anomalies.filter(
        (item) => item.status == "OPEN",
      ).length;

      console.log("openTicketCount", openTicketCount);

      const majorSeverityCount = tickets.filter(
        (item) => item.status == "OPEN" && item.severity == "Major",
      ).length;
      console.log("majorCount", majorSeverityCount);

      const minorSeverityCount = tickets.filter(
        (item) => item.status == "OPEN" && item.severity == "Minor",
      ).length;
      console.log("minorCount", minorSeverityCount);
      const criticalSeverityCount = tickets.filter(
        (item) => item.status == "OPEN" && item.severity == "Critical",
      ).length;
      console.log("criticalCount", criticalSeverityCount);
      const scheduleSeverityCount = tickets.filter(
        (item) => item.status == "OPEN" && item.severity == "Schedule",
      ).length;
      console.log("scheduleSeverityCount", scheduleSeverityCount);
      const severeSeverityCount = tickets.filter(
        (item) => item.status == "OPEN" && item.severity == "Severe",
      ).length;
      console.log("severSeverityCount", severeSeverityCount);

      if (tickets === null || tickets === undefined) {
        return {
          ...state,
          isLoading: false,
          isError: true,
        };
      }

      const unreliableData = tickets.filter(function (ticket) {
        if (ticket.issue_type != null) {
          return ticket.issue_type.includes("Unreliable Data");
        } else {
          return false;
        }
      });

      const failureTickets = tickets.filter(function (ticket) {
        if (ticket.issue_type != null) {
          return (
            ticket.issue_type.includes("Possible Failures") ||
            ticket.issue_type.includes("Possible Failure") ||
            ticket.issue_type.includes("Flat Consecutive Ticks") ||
            ticket.issue_type.includes("Inverter Maintenance")
          );
        } else {
          return false;
        }
      });

      const alertTickets = tickets.filter(function (ticket) {
        if (ticket.issue_type != null) {
          return ticket.issue_type.endsWith("Alert");
        } else {
          return false;
        }
      });

      const longTermDegarded = tickets.filter(function (ticket) {
        if (ticket.issue_type != null) {
          return (
            !ticket.issue_type.includes("Possible Failures") &&
            !ticket.issue_type.includes("Possible Failure") &&
            !ticket.issue_type.includes("Inverter Maintenance") &&
            !ticket.issue_type.includes("Unreliable Data") &&
            !ticket.issue_type.endsWith("Alert") &&
            !ticket.issue_type.includes("Flat Consecutive Ticks")
          );
        } else {
          return false;
        }
      });

      const manualTicketData = tickets.filter(function (ticket) {
        if (ticket.issue_type != null) {
          return ticket.issue_type.includes("manual");
        } else {
          return false;
        }
      });

      const majorSeverityTickets = tickets.filter(function (ticket) {
        if (ticket.severity != null) {
          return ticket.severity == "Major";
        } else {
          return false;
        }
      });

      const minorSeverityTickets = tickets.filter(function (ticket) {
        if (ticket.severity != null) {
          return ticket.severity == "Minor";
        } else {
          return false;
        }
      });

      const criticalSeverityTickets = tickets.filter(function (ticket) {
        if (ticket.severity != null) {
          return ticket.severity == "Critical";
        } else {
          return false;
        }
      });

      const scheduleTickets = tickets.filter(function (ticket) {
        if (ticket.severity != null) {
          return ticket.severity == "Schedule";
        } else {
          return false;
        }
      });
      const severeTickets = tickets.filter(function (ticket) {
        if (ticket.severity != null) {
          return ticket.severity == "Severe";
        } else {
          return false;
        }
      });

      const tickets_data = {
        unreliableData: unreliableData,
        failureTickets: failureTickets,
        longTermDegarded: longTermDegarded,
        alertTickets: alertTickets,
        manualTickets: manualTicketData,
        tickets: tickets,
        openTicketCount,
        majorSeverityCount,
        minorSeverityCount,
        criticalSeverityCount,
        severeSeverityCount,
        majorSeverityTickets,
        minorSeverityTickets,
        criticalSeverityTickets,
        scheduleSeverityCount,
        scheduleTickets,
        severeTickets,
      };

      return {
        ...state,
        isLoading: false,
        isError: false,
        data: tickets_data,
      };
    case "GRAPHS_FETCH_FAILUE":
      return {
        ...state,
        isLoading: false,
        isError: true,
      };
    default:
      return {
        ...state,
        isLoading: false,
        isError: true,
      };
  }
}

export function activitiesReducer(state, action) {
  switch (action.type) {
    case "GRAPHS_FETCH_INIT":
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    case "GRAPHS_FETCH_SUCCESS":
      return {
        ...state,
        isLoading: false,
        isError: false,
        data: action.payload,
      };
    case "GRAPHS_FETCH_FAILUE":
      return {
        ...state,
        isLoading: false,
        isError: true,
      };
    default:
      return {
        ...state,
        isLoading: false,
        isError: true,
      };
  }
}

export function ticketHistoryTableReducer(state, action) {
  switch (action.type) {
    case "GRAPHS_FETCH_INIT":
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    case "GRAPHS_FETCH_SUCCESS":
      return {
        ...state,
        isLoading: false,
        isError: false,
        data: action.payload,
      };
    case "GRAPHS_FETCH_FAILUE":
      return {
        ...state,
        isLoading: false,
        isError: true,
      };
    default:
      return {
        ...state,
        isLoading: false,
        isError: true,
      };
  }
}

export function meterMetricTableReducer(state, action) {
  switch (action.type) {
    case "GRAPHS_FETCH_INIT":
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    case "GRAPHS_FETCH_SUCCESS":
      const modifiedTableData = action.payload.data.map((item) => ({
        nodeName: item[0],
        capacity: item[1],
        energy: item[2],
        pr: item[3],
      }));
      return {
        ...state,
        isLoading: false,
        isError: false,
        data: modifiedTableData,
      };
    case "GRAPHS_FETCH_STATIC":
      const static_data = {
        data: [
          ["BLOCK-1_INV-A", 3778.8, 14064.0, 73.11],
          ["BLOCK-1_INV-B", 3753.6, 13995.0, 73.24],
          ["BLOCK-1_INV-C", 3753.6, 14098.0, 73.78],
          ["BLOCK-1_INV-D", 3753.6, 13923.0, 72.87],
          ["BLOCK-2_INV-A", 3753.6, 14457.0, 75.66],
          ["BLOCK-2_INV-B", 3753.6, 14299.0, 74.83],
          ["BLOCK-2_INV-C", 3753.6, 14006.0, 73.3],
          ["BLOCK-2_INV-D", 3753.6, 14578.0, 76.3],
          ["BLOCK-3_INV-A", 3753.6, 14553.0, 76.16],
          ["BLOCK-3_INV-B", 3753.6, 14300.0, 74.84],
          ["BLOCK-3_INV-C", 3698.4, 14479.0, 76.91],
          ["BLOCK-3_INV-D", 3757.2, 14708.0, 76.9],
          ["BLOCK-4_INV-A", 3778.8, 14351.0, 74.61],
          ["BLOCK-4_INV-B", 3778.8, 14396.0, 74.84],
          ["BLOCK-4_INV-C", 3726.0, 14264.0, 75.2],
          ["BLOCK-4_INV-D", 3726.0, 14113.0, 74.41],
          ["BLOCK-5_INV-A", 3767.4, 14185.0, 73.97],
          ["BLOCK-5_INV-B", 3767.4, 14509.0, 75.66],
          ["BLOCK-5_INV-C", 3767.4, 14218.0, 74.14],
          ["BLOCK-5_INV-D", 3767.4, 14111.0, 73.58],
          ["BLOCK-6_INV-A", 3767.4, 14229.0, 74.19],
          ["BLOCK-6_INV-B", 3767.4, 14021.0, 73.11],
          ["BLOCK-6_INV-C", 3698.4, 14093.0, 74.86],
          ["BLOCK-6_INV-D", 3698.4, 14183.0, 75.33],
          ["BLOCK-7_INV-A", 3753.6, 14338.0, 75.04],
          ["BLOCK-7_INV-B", 3753.6, 14282.0, 74.74],
          ["BLOCK-7_INV-C", 3223.2, 12644.0, 77.06],
          ["BLOCK-7_INV-D", 3223.2, 2957441.0, 18025.11],
          ["BLOCK-8_INV-A", 3738.6, 14067.0, 73.92],
          ["BLOCK-8_INV-B", 3698.4, 14203.0, 75.44],
          ["BLOCK-8_INV-C", 3698.4, 14107.0, 74.93],
          ["BLOCK-8_INV-D", 3698.4, 13913.0, 73.9],
          ["BLOCK-9_INV-A", 3698.4, 13977.0, 74.24],
          ["BLOCK-9_INV-B", 3698.4, 14085.0, 74.81],
          ["BLOCK-9_INV-C", 3698.4, 13892.0, 73.79],
          ["BLOCK-9_INV-D", 3698.4, 13922.0, 73.95],
          ["BLOCK-10_INV-A", 3778.8, 14856.0, 77.23],
          ["BLOCK-10_INV-B", 3778.8, 15083.0, 78.41],
          ["BLOCK-10_INV-C", 3778.8, 12907.0, 67.1],
          ["BLOCK-10_INV-D", 3738.6, 14648.0, 76.97],
          ["BLOCK-11_INV-A", 3643.2, 14644.0, 78.96],
          ["BLOCK-11_INV-B", 3643.2, 3110043.0, 16769.98],
          ["BLOCK-11_INV-C", 3564.0, 14401.0, 79.38],
          ["BLOCK-11_INV-D", 3564.0, 14341.0, 79.05],
          ["BLOCK-12_INV-A", 3117.6, 12573.0, 79.22],
          ["BLOCK-12_INV-B", 3103.8, 11382.0, 72.04],
          ["BLOCK-12_INV-C", 3537.6, 13818.0, 76.74],
          ["BLOCK-12_INV-D", 3537.6, 8715.0, 48.39],
          ["BLOCK-13_INV-A", 3687.0, 15036.0, 80.11],
          ["BLOCK-13_INV-B", 3638.1, 14638.0, 79.04],
          ["BLOCK-13_INV-C", 3698.4, 14964.0, 79.48],
          ["BLOCK-13_INV-D", 3618.0, 14585.0, 79.19],
          ["BLOCK-14_INV-A", 3523.8, 14013.0, 78.12],
          ["BLOCK-14_INV-B", 3519.6, 13945.0, 77.83],
          ["BLOCK-14_INV-C", 3528.0, 14152.0, 78.8],
          ["BLOCK-14_INV-D", 3528.0, 14005.0, 77.98],
        ],
        header: ["Node", "Capacity (KW)", "Energy (KWH)", "PR (%)"],
      };

      return {
        ...state,
        isLoading: false,
        isError: false,
        data: static_data,
      };
    case "GRAPHS_FETCH_FAILUE":
      return {
        ...state,
        isLoading: false,
        isError: true,
      };
    default:
      return {
        ...state,
        isLoading: false,
        isError: true,
      };
  }
}
