import React, { useState, useRef, useEffect } from "react";
import { Button, Modal, ModalBody, Input } from "reactstrap";
import axios from "axios";
import useAppContext from "context/state";

function Modalright({
  auditList,
  index,
  onSelectedAudit,
  auditList_id,
  report_title,
}) {
  const [modalOpen, setModalOpen] = React.useState(false);
  const [selectedRadio, setSelectedRadio] = useState({
    audit_id: auditList_id,
    report_title: report_title,
  });
  const [inputValue, setInputValue] = useState("");

  const AuditReportAction = () => (
    <div
      className="d-inline-flex justify-content-between align-items-center "
      onClick={() => setModalOpen(!modalOpen)}
    >
      {index === "Index" ? (
        <h1 className="sm-font font-bold qblue-clr mb-3 mb-md-4 cursor-pointer">
          {report_title}
        </h1>
      ) : (
        <>
          <div>
            <p className="mb-1 xs-font font-bold">Audit Report</p>
            <p className="body-font2 mb-0 font-reglar cursor-pointer">
              {report_title}
            </p>
          </div>
          <svg className="ic_ ic_min dark-grey">
            <use xlinkHref="#ic_arrow_right" />
          </svg>
        </>
      )}
    </div>
  );

  const changeAuditHandler = (e) => {
    onSelectedAudit(e.currentTarget.value);
    setSelectedRadio({
      audit_id: e.currentTarget.value,
      report_title: e.target.name,
    });
  };

  const searchChangeHandler = (e) => {
    setInputValue(e.target.value);
  };

  return (
    <>
      <AuditReportAction></AuditReportAction>
      <div className="modal-parent">
        <Modal
          className="modal-right modal-filter"
          toggle={() => setModalOpen(!modalOpen)}
          isOpen={modalOpen}
        >
          <div className="modal-header align-items-center">
            <h5 className="modal-title" id="exampleModalLabel">
              Filter
            </h5>

            <span
              aria-hidden={true}
              aria-label="Close"
              className="p-0 close"
              type="button"
              onClick={() => setModalOpen(!modalOpen)}
            >
              <svg className="ic_">
                <use xlinkHref="#ic_close" />
              </svg>
            </span>
          </div>
          <ModalBody className="p-0">
            {/* search input */}
            <Input
              placeholder="Search Audit Report"
              value={inputValue}
              onChange={searchChangeHandler}
              type="text"
              className="rounded-0 search-input mb-0"
            ></Input>
            {/*{console.log("auditList1", auditList)}*/}
            {auditList["data"]
              .filter((audit) => {
                if (inputValue == "") {
                  return audit;
                } else if (
                  audit.report_title
                    .toLowerCase()
                    .includes(inputValue.toLowerCase())
                ) {
                  return audit;
                }
              })
              .map((audit) => (
                <div className="plant-radio" key={audit.audit_id}>
                  <input
                    id={audit.audit_id}
                    type="radio"
                    value={audit.audit_id}
                    checked={selectedRadio.audit_id == audit.audit_id}
                    name={audit.report_title}
                    onChange={changeAuditHandler}
                    //   ref = {auditIdRef}
                  ></input>
                  <label htmlFor={audit.audit_id}>{audit.report_title}</label>
                </div>
              ))
              .reverse()}
          </ModalBody>
        </Modal>
      </div>
    </>
  );
}

export default Modalright;
