import React,{useEffect, useState} from "react";
// reactstrap components
import {
Button,
Card,
CardHeader,
CardBody,
Collapse,
FormGroup,
Input,
Label,
Container,
Row,
Col,
} from "reactstrap";
function Acordion(props) {
const [openedCollapse, setOpenedCollapse] = React.useState("collapseOne");
const [unitFilter, setUnitFilter]= useState(props.unitFilter ? props.unitFilter: "energyUnit");
const [currencyFilter, setcurrencyFilter]= useState(props.currencyFilter? props.currencyFilter: "INR");
const [energyFilter, setEnergyFilter]= useState(props.unitFilter =="energyUnit"? props.unit:"MWh");
const [periodFilter, setperiodFilter]= useState(props.periodFilter? props.periodFilter: "weekly");
//const [unit, setUnit]= useState(props.unit? props.unit: "Mwh");

//setUnitFilter(props.unitFilter);
const clickUnitHandler = (e) => {
  setUnitFilter(e.target.value);

};

const energyUnitChangeHandler = (e)=>{
  setEnergyFilter(e.target.value);


}


 const periodFilterHandler = (e) => {
  setperiodFilter(e.target.value);
 }



useEffect(() => {
  const selectedFilters ={
    unitFilter: unitFilter,
    currencyFilter: currencyFilter,
    energyFilter:energyFilter,
    periodFilter:periodFilter
  }

  props.selectUnitOption(selectedFilters);

},[clickUnitHandler, energyUnitChangeHandler, periodFilterHandler])


return (
<>
<div className=" accordion-1">
  <div className=" accordion" id="accordionExample">
    <div>
      <CardHeader
      id="headingOne" className="p-0 d-flex justify-content-between align-items-center border-0"
      aria-expanded={openedCollapse === "collapseOne"}
      >
      <h5 className="mb-0 flex-fill">
        <Button
          onClick={() =>
        setOpenedCollapse(
        openedCollapse === "collapseOne"
        ? ""
        : "collapseOne"
        )
        }
        className="w-100 text-primary text-left border-0 rounded-0"
        color="link"
        >
        Dashboard Unit{" "}
        </Button>
      </h5>
      </CardHeader>
      <Collapse
      isOpen={openedCollapse === "collapseOne"}
      aria-labelledby="headingOne"
      data-parent="#accordionExample"
      id="collapseOne"
      >
      <CardBody className=" opacity-8">
        <div>
         <div ><FormGroup>
          <div className="radio-btn-wrapper two-radio-wrapper ">
            <input
              id="customRadioInline1"
              name="customRadioInline1"
              value = "currency"
              type="radio" 
              onChange={clickUnitHandler}
              checked= {unitFilter =="currency"} 
              ></input>
            <label  htmlFor="customRadioInline1">
            Currency
            </label>
            <input
              id="customRadioInline2"
              name="customRadioInline1"
              type="radio"  
              value = "energyUnit"
              onChange = {clickUnitHandler}
              checked= {unitFilter =="energyUnit"} 
             
              ></input>
            <label htmlFor="customRadioInline2">
            {energyFilter}
            </label>
          </div>
            </FormGroup>
            { unitFilter === "currency" && 
              <FormGroup>
                <select name="currency" className="custom-select mb-0">
                    <option value = "Indian Rupee"  >&#8377;Indian Rupee - INR </option>
                </select>
                </FormGroup> 
            }
            { unitFilter === "energyUnit" && 
              <FormGroup>
                <select name="energyUnit" value={energyFilter} onChange = {energyUnitChangeHandler } className="custom-select mb-0">
                    <option value = "MWh"  > MWh</option>
                    <option value = "kWh" > kWh</option>
                </select>
              </FormGroup>
            }

            { //props.activeTabId === '1' && 
              <FormGroup className="mb-0">
                           <Label className="font-bold themetext-clr xs-font">Data Duration Unit</Label>
                            <select name="periodicly" value={periodFilter} onChange = {periodFilterHandler } className="custom-select mb-0">
                              <option value ={"weekly"}>Weekly</option>
                              <option value ={"biweekly"}>Biweekly</option>
                              <option  value ={"monthly"}>Monthly</option>
                            </select>
              </FormGroup>
            }              
            </div >
                       
                         
        </div>
      </CardBody>
      </Collapse>
    </div>
  </div>
</div>
</>
);
}
export default Acordion;