import React, { useState } from "react";
import Link from "next/link";
import { useRouter } from "next/router";
import useAppContext from "context/state";
import {useSession} from "next-auth/client";
import moment from 'moment-timezone';



import {
    Button,
    Row,
    Col,
    Container,
    Breadcrumb,
    BreadcrumbItem,
    Form,
    FormGroup,
    UncontrolledCollapse, Label, Input, Nav, NavItem, NavLink, TabContent, TabPane
} from "reactstrap";
import DatePickerCalendar from "../MainContent/DatePickerComponent";
import trim_tree from "../../plant_variables/trim_tree.json";
import ReactSlidingPane from "react-sliding-pane";
import classnames from "classnames";
import Modalright from "../AuditUi/FilterModal";
import Accordion from "../AuditUi/Accordion";
import Icons from "../AuditUi/Icons/Icons";
import LZString from "lz-string"

const TopRightNav = (props) => {
    const api = useAppContext();

    const [session, loading] = useSession();

    const [headerLinks,setHeaderLinks] = useState(session.user.plants["HEADER_SUB_LINKS"]!=undefined ? session.user.plants["HEADER_MAIN_LINKS"] : process.env.HEADER_MAIN_LINKS)

  

    const localTime = process.env.TIME_ZONE_CS;
    const guessedTimeZone = 'Asia/Kolkata';
 

        //     const unixepochToDateObj = (number) => {

        //     var s = moment.unix(number).toDate()
        //     if (number === undefined) s = new Date();
    
        //     const obj = {
        //         year: s.getFullYear(),
        //         month: s.getMonth() + 1,
        //         day: s.getDate(),
        //     };
        //     return obj;
        // };

    // const unixepochToDateObj = (number, timezone = 'Asia/Kolkata') => {
    //     console.log('Input number:', number);
    //     console.log('Input timezone:', timezone);
    
    //     var s = moment.unix(number).tz(timezone);
    //     console.log('Moment object:', s);
    
    //     const obj = {
    //         year: s.year(),
    //         month: s.month() + 1,
    //         day: s.date(),
    //     };
    //     console.log('Output object:', obj);
    
    //     return obj;
    // };

  

    const unixepochToDateObj = (number, timezone = 'Asia/Kolkata') => {
        // console.log('Input number:', number);
        // console.log('Input timezone:', timezone);
    
        var s;
        if (number === undefined) {
            s = moment().tz(timezone);
        } else {
            s = moment.unix(number).tz(timezone);
        }
        // console.log('Moment object:', s);
    
        const obj = {
            year: s.year(),
            month: s.month() + 1,
            day: s.date(),
        };
        // console.log('Output object:', obj);
    
        return obj;
    };
    


    
    

    const router = useRouter();

    const [selectedDay, setSelectedDay] = useState(
        unixepochToDateObj(api.ticketDate)
    );

    // console.log("ApiTicketDate>>>>>>",api.ticketDate)
    //  console.log("SelectedDay>>>>>>",selectedDay)
    const [activeTab, setActiveTab] = useState("1");

    var plantNameMap = {};
    trim_tree["nodes"].forEach(function (item) {
        plantNameMap[item.plant_id] = item.label;
    });

    const [unitFilter, setUnitFilter] = useState("energyUnit");
    const [currencyFilter, setcurrencyFilter] = useState("INR");
    const [unit, setUnit] = useState("MWh");
    const [energyFilter, setEnergyFilter] = useState("MWh");
    const [periodFilter, setperiodFilter] = useState("weekly");
    const didMountRef = React.useRef(false);
    const serialize = (o) => {
        return LZString.compressToEncodedURIComponent(JSON.stringify(o));
    };
    const deserialize = (s) => {
        return JSON.parse(LZString.decompressFromEncodedURIComponent(s));
    };
    // console.log("props>>>>>>>>>>>>>>>>>>>>>>>>..",props)
    
    const slug = router.query;
    const toggle = (tab) => {
        if (activeTab !== tab) setActiveTab(tab);
    };
    // const [selectedReading, setSelectedReadings] = React.useState((slug.rt_l==undefined && typeof window !== 'undefined' && localStorage.getItem("mr-sr")!=undefined)? deserialize(localStorage.getItem("mr-sr")):props.selected_reading)
    // console.log("selectedReadingInEvents",selectedReading)


    // const defaultRange = {
    //     from: props.startDate == undefined ? unixepochToDateObj(api.startDate) : unixepochToDateObj(props.startDate),
    //     to: props.endDate == undefined ? unixepochToDateObj(api.endDate) : unixepochToDateObj(props.endDate),
    // };

    const defaultRange = {
        from: props.startDate == undefined ? unixepochToDateObj(api.startDate) : unixepochToDateObj(props.startDate),
        to: props.endDate == undefined ? unixepochToDateObj(api.endDate) : unixepochToDateObj(props.endDate),
    };
    

    // console.log("defaultRange>>>>>>>>>>>>>>>>>.",defaultRange)


    const [selectedDayRange, setSelectedDayRange] = useState(defaultRange);
    const [selectedFutureDayRange, setSelectedFutureDayRange] = useState(defaultRange);
    // console.log("selectedDayRange>>>>>>>>>>>>",selectedDayRange)

    // const handleDayRange = React.useCallback(async () => {
    //     console.log("handleDayRange  TRN... ")

    //     if (selectedDayRange.to && selectedDayRange.from) {

    //         let start_date = moment(`${selectedDayRange.from.year}-${selectedDayRange.from.month}-${selectedDayRange.from.day}`).startOf('day');
    //         let end_date = moment(`${selectedDayRange.to.year}-${selectedDayRange.to.month}-${selectedDayRange.to.day}`).endOf('day');
           
    //         console.log("start_date unix",start_date)
    //         console.log("end_date unix",end_date)

    //         api.onDateChange(
    //             (start_date.unix()),
    //             (end_date.unix())
    //         );
    //          var diffDays = end_date.diff(start_date, 'days') // 1
    //             router.push({
    //                 pathname: router.pathname,
    //                 query: {
    //                     ...router.query,
    //                     start_date: (start_date.unix()),
    //                     end_date: (end_date.unix()),
    //                     days: diffDays,
    //                 },
    //             },undefined, { shallow: true });
    //     }
    // }, [selectedDayRange]);

    const handleDayRange = React.useCallback(async () => {
        // console.log("handleDayRange  TRN... ")
    
        if (selectedDayRange.to && selectedDayRange.from) {
            let timezone = guessedTimeZone;
    
            let start_date = moment.tz(`${selectedDayRange.from.year}-${selectedDayRange.from.month}-${selectedDayRange.from.day}`, 'YYYY-MM-DD', timezone).startOf('day');
            let end_date = moment.tz(`${selectedDayRange.to.year}-${selectedDayRange.to.month}-${selectedDayRange.to.day}`, 'YYYY-MM-DD', timezone).endOf('day');

            //
            // console.log("start_date unix", start_date)
            // console.log("end_date unix", end_date)
    
            api.onDateChange(
                (start_date.tz(timezone).unix()),
                (end_date.tz(timezone).unix()) // Use end_date here
            );
            var diffDays = end_date.diff(start_date, 'days')
            router.push({
                pathname: router.pathname,
                query: {
                    ...router.query,
                    start_date: (start_date.tz(timezone).unix()),
                    end_date: (end_date.tz(timezone).unix()), // And here
                     days: diffDays,
                },
            }, undefined, { shallow: true });
        }
    }, [selectedDayRange]);
    

    const handleFutureDayRange = React.useCallback(() => {
        console.log("abcd>>>>>>>>>>>>>>>")
        if (selectedFutureDayRange.to) {
            let timezone = guessedTimeZone;
            // let start_date = moment.tz(`${selectedDayRange.from.year}-${selectedDayRange.from.month}-${selectedDayRange.from.day}`, 'YYYY-MM-DD', timezone).startOf('day');
            // let end_date = moment.tz(`${selectedDayRange.to.year}-${selectedDayRange.to.month}-${selectedDayRange.to.day}`, 'YYYY-MM-DD', timezone).endOf('day');
            let start_date = moment.tz(`${selectedDayRange.from.year}-${selectedDayRange.from.month}-${selectedDayRange.from.day}`,'YYYY-MM-DD',timezone).startOf('day');
            let end_date = moment.tz(`${selectedDayRange.to.year}-${selectedDayRange.to.month}-${selectedDayRange.to.day}`,'YYYY-MM-DD',timezone).endOf('day');

            api.onDateChange(
                (start_date.tz(timezone).unix()),
                (end_date.tz(timezone).unix())
            );
        }
    }, [selectedFutureDayRange]);

    const handleDaySelection = React.useCallback(() => {
         console.log("handleDaySelection called",selectedDay)
         let timezone = guessedTimeZone;
        let current_date = moment.tz(`${selectedDay.year}-${selectedDay.month}-${selectedDay.day}`,'YYYY-MM-DD',timezone).startOf('day');
            api.onDateChange(
                (current_date.tz(timezone).unix()),
                (current_date.tz(timezone).unix())
            );

        router.push({
            pathname: router.pathname,
            query: {
                ...router.query,
                start_date: (current_date.tz(timezone).unix()),
                end_date: (current_date.tz(timezone).unix())
            },
        });

    }, [selectedDay]);

    const selectfilterUnit = ({
                                  unitFilter,
                                  currencyFilter,
                                  periodFilter,
                                  energyFilter,
                              }) => {
        setUnitFilter(unitFilter);
        setcurrencyFilter(currencyFilter);
        if (unitFilter === "currency") {
            setUnit(currencyFilter);
        } else if (unitFilter === "energyUnit") {
            setUnit(energyFilter);
        }
        setperiodFilter(periodFilter);
        onFilterChange({unit, periodFilter});
    };

    const selectedAudit = (audit_id) => {
        onselectedAuditFromModel(audit_id);
    };
    const [isQuadrical, setIsQuadrical] = useState(session.user.email.includes("quadrical"))

    React.useEffect(() => {
        if (didMountRef.current) {

            if (["Operations", "plantAvailability"].includes(parentName)) {
                handleDaySelection();
            }
        } else didMountRef.current = true;
    }, [handleDaySelection]);

    React.useEffect(() => {
        if (didMountRef.current) {
            handleDayRange();

        } else didMountRef.current = true;
    }, [handleDayRange]);

    const parentName = api.getParentNameFromUrl(router.pathname)

    return (<>

        <div className="  pull-right">
            {/* {headerLinks.includes("rules")? <button className=" mr-4  btn-icon text-gray-dark border-0 backdrop-light"  type="button">
                <Link href={`/rules?plant_id=${api.plant_id}&meter_id=${api.currentMeter}`} >
                            <span>         <i className="ni ni-ruler-pencil " style={{fontSize:"1.2em"}} ></i>
                </span>
                </Link>
            </button> : null} */}


            {["Reports","landingPage","Plant Rules","tmsReport","portfolioReport"].includes(parentName) ? null : parentName ===
            "Operations" || parentName === "plantAvailability" ? (
                <DatePickerCalendar
                    parenName="datePicker"
                    day={selectedDay}
                    dayHandler={setSelectedDay}
                />
            ) : parentName === "Audit" ? (
                <DatePickerCalendar
                    parenName="datePicker"
                    day={selectedDayRange}
                    dayHandler={setSelectedDayRange}
                />
            ) : (
                <>
                    <DatePickerCalendar
                        parenName="datePicker"
                        day={selectedDayRange}
                        dayHandler={setSelectedDayRange}
                    />
                </>
            )}

            {["SummaryTableSingle", "Reports", "portfolio", "Rules", "landingPage","tmsReport","Plant Rules", "portfolioReport"].includes(
                parentName
            ) ? null : (
                <button
                    onClick={(e) => {
                        e.preventDefault();
                        api.setPaneState(true);
                    }}
                    className=" ml-4 btn-icon text-gray-dark border-0 backdrop-light"
                >
                                    <span>
                                      <i className="ni ni-settings-gear-65" style={{fontSize:"1.2em"}} />
                                    </span>
                </button>
            )}

        </div>

    </>)
                      }


export default TopRightNav;
