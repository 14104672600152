import full_tree from "plant_variables/full_tree.json";
import trim_tree from "../../plant_variables/trim_tree.json";

export function plantLevelMeter(entry) {
  const plantEntry = full_tree.nodes.filter((sub) => sub.node_name == entry)[0];
  return { meter_id: plantEntry.meter_id, plant_id: plantEntry.plant_id };
}

export function topLevel() {
  const plantEntry = full_tree.nodes.map((item) => {
    return { name: item.node_name, id: item.plant_id, meter_id: item.meter_id };
  });
  return plantEntry;
}

export function topLevelUser(session) {
  let tree = {
    ...full_tree,
    nodes: full_tree["nodes"].filter((value) =>
      session.user.plants["USER"].includes(value["plant_id"]),
    ),
  };
  const plantEntry = tree.nodes.map((item) => {
    return { name: item.node_name, id: item.plant_id, meter_id: item.meter_id };
  });
  return plantEntry;
}

export function levelOneEntry(entry) {
  const plantEntry = full_tree.nodes
    .filter((sub) => sub.plant_id == entry)[0]
    .nodes.map((item) => {
      return { name: item.node_name, id: item.meter_id };
    });
  return plantEntry;
}

export function levelTwoEntry(entry, levelTwo) {
  const plantEntry = full_tree.nodes
    .filter((sub) => sub.plant_id == entry)[0]
    .nodes.filter((item) => item.meter_id == levelTwo)[0]
    .nodes.map((item) => {
      return { name: item.node_name, id: item.meter_id };
    });
  return plantEntry;
}

export function levelThreeEntry(entry, levelTwoEntry, levelThree) {
  const plantEntry = full_tree.nodes
    .filter((sub) => sub.plant_id == entry)[0]
    .nodes.filter((item) => item.meter_id == levelTwoEntry)[0]
    .nodes.filter((item) => item.meter_id == levelThree)[0]
    .nodes.map((item) => {
      return { name: item.node_name, id: item.meter_id };
    });
  return plantEntry;
}

export function levelFourEntry(entry, levelTwoEntry, levelThree, levelFour) {
  const plantEntry = full_tree.nodes
    .filter((sub) => sub.plant_id == entry)[0]
    .nodes.filter((item) => item.meter_id == levelTwoEntry)[0]
    .nodes.filter((item) => item.meter_id == levelThree)[0]
    .nodes.filter((item) => item.meter_id == levelFour)[0]
    .nodes.map((item) => {
      return { name: item.node_name, id: item.meter_id };
    });
  return plantEntry;
}

export function levelFiveEntry(
  entry,
  levelTwoEntry,
  levelThree,
  levelFour,
  levelFive,
) {
  const plantEntry = full_tree.nodes
    .filter((sub) => sub.plant_id == entry)[0]
    .nodes.filter((item) => item.meter_id == levelTwoEntry)[0]
    .nodes.filter((item) => item.meter_id == levelThree)[0]
    .nodes.filter((item) => item.meter_id == levelFour)[0]
    .nodes.filter((item) => item.meter_id == levelFive)[0]
    .nodes.map((item) => {
      return { name: item.node_name, id: item.meter_id };
    });
  return plantEntry;
}
export function levelSixEntry(
  entry,
  levelTwoEntry,
  levelThree,
  levelFour,
  levelFive,
  levelSix,
) {
  const plantEntry = full_tree.nodes
    .filter((sub) => sub.plant_id == entry)[0]
    .nodes.filter((item) => item.meter_id == levelTwoEntry)[0]
    .nodes.filter((item) => item.meter_id == levelThree)[0]
    .nodes.filter((item) => item.meter_id == levelFour)[0]
    .nodes.filter((item) => item.meter_id == levelFive)[0]
    .nodes.filter((item) => item.meter_id == levelSix)[0]
    .nodes.map((item) => {
      return { name: item.node_name, id: item.meter_id };
    });
  return plantEntry;
}
export function levelSevenEntry(
  entry,
  levelTwoEntry,
  levelThree,
  levelFour,
  levelFive,
  levelSix,
  levelSeven,
) {
  const plantEntry = full_tree.nodes
    .filter((sub) => sub.plant_id == entry)[0]
    .nodes.filter((item) => item.meter_id == levelTwoEntry)[0]
    .nodes.filter((item) => item.meter_id == levelThree)[0]
    .nodes.filter((item) => item.meter_id == levelFour)[0]
    .nodes.filter((item) => item.meter_id == levelFive)[0]
    .nodes.filter((item) => item.meter_id == levelSix)[0]
    .nodes.filter((item) => item.meter_id == levelSeven)[0]
    .nodes.map((item) => {
      return { name: item.node_name, id: item.meter_id };
    });
  return plantEntry;
}
