const unixepochToDateObj = (number) => {
    var s = new Date(number * 1000);
    const obj = {
      year: s.getFullYear(),
      month: s.getMonth() + 1,
      day: s.getDate(),
    };
    return obj;
  };


export function calculateMaxMinDate(Itemlist, plantID) {
    let date = {};
    for (var i = 0; i < Itemlist.length; i++) {
      if (Itemlist[i].plant_id == plantID) {
        date = Itemlist[i];
        break;
      } else continue;
    }
    const minimumDate =
      typeof unixepochToDateObj(date.dashboard_start) === "undefined"
        ? unixepochToDateObj(1277977357)
        : unixepochToDateObj(date.dashboard_start);
    const maximumDate = unixepochToDateObj(date.max_date_hourly);
    return [minimumDate, maximumDate];
  };


  export function calculateTicketDate(Itemlist, plantID) {
    let date = {};
    for (var i = 0; i < Itemlist.length; i++) {
      if (Itemlist[i].plant_id == plantID) {
        date = Itemlist[i];
        break;
      } else continue;
    }
    const ticketDate =
      typeof date.max_date_tickets === "undefined"
        ? 1277977357
        : date.max_date_tickets;
    return ticketDate;
  };