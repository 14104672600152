import React, { Component, useEffect, useState } from "react";
import axios from "axios";
import useAppContext from "context/state";
import trim_tree from "plant_variables/trim_tree.json";
import Link from "next/link";
import GoogleMapReact from "google-map-react";
import indiaJson from "../../india_state.json";
import worldJson from "../../world.json";
import vietnamJson from "../../vietnam.json";

import ReactECharts from "echarts-for-react";
import * as echarts from "echarts";
echarts.registerMap("india", indiaJson);
echarts.registerMap("world", worldJson);
echarts.registerMap("vietnam", vietnamJson);

import { defaultDataReducer } from "components/Reducers/dataReducers";
import { plantLevelMeter } from "components/Utility/treeReading";
import {
  Card,
  CardTitle,
  Row,
  Col,
  CardBody,
  UncontrolledTooltip,
} from "reactstrap";
import { summaryTableReducer } from "components/Reducers/tableDataReducers";
import { useSession, getSession } from "next-auth/client";
import { PlantMetaDatageo } from "components/MainContent/plantMetadata";
import {
  drawLocations,
  GeoLocations,
  getLocation,
} from "../Utility/geoMapUtilFunc";
import BarLoader from "react-spinners/BarLoader";
import ClimbingBoxLoader from "react-spinners/ClimbingBoxLoader";
import { css } from "@emotion/react";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const defaultProps = {
  center: { lat: 22.5937, lng: 80.9629 },
  zoom: 4.5,
  key: "AIzaSyD6AUPIR0eIiGldIIo0b06uqLxlZDyQh-I",
};

export function GeoMapDraw({ startDate, endDate }) {

  const api = useAppContext();
  const [session, loading] = useSession();

  const getOption = () => {
    // Generate data for map
    let mapData = dashboardData.data.data.map((item) => {
      return {
        name: item["PLANT NAME"],
        value: [item["coord"][1], item["coord"][0], item["kpi_actual"]] // Note the order [longitude, latitude]
      };
    });

    return {
      title: {
        text: 'Geo Map',
        left: 'center'
      },
      tooltip: {
        trigger: 'item'
      },
      geo: {
        map: process.env.MAP_JSON==undefined?"india":process.env.MAP_JSON ,
        roam: process.env.MAP_JSON!=undefined &&  process.env.MAP_JSON == "world" ?true:false,
        label: {
          emphasis: {
            show: false
          }
        },
      },
      series: [
        {
          name: 'Plants',
          type: 'scatter',
          coordinateSystem: 'geo',
          data: mapData,
          symbolSize: function (val) {
            return val[2] / 10;
          },
          encode: {
            value: 2
          },
          label: {
            formatter: '{b}',
            position: 'right',
            show: false
          },
          emphasis: {
            label: {
              show: true
            }
          }
        }
      ]
    };
  }


   
  const treeObj = {
    ...trim_tree,
    nodes: trim_tree["nodes"].filter((value) =>
      session.user.plants["USER"].includes(value["plant_id"])
    ),
  };
  const userplants = session.user.plants["USER"];

  const plant_list = {
    ...treeObj,
    nodes: treeObj["nodes"].filter((value) =>
      userplants.includes(value["plant_id"])
    ),
  }["nodes"].map((item) => item["plant_id"]);

  
  const [dashboardData, dispatchDashboardtData] =
  React.useReducer(defaultDataReducer, {
    data: {data : []},
    isLoading: false,
    isError: false,
  });

  const [summaryTablesPastData, dispatchSummaryTablePastData] =
    React.useReducer(summaryTableReducer, {
      data: [],
      isLoading: false,
      isError: false,
    });


  const handleSummaryPastData = React.useCallback(async () => {
    let merged = {};
    var mergedList = [];
    var keysList = [];
    let tempInitialObj = {};
    dispatchSummaryTablePastData({ type: "GRAPHS_FETCH_INIT" });
    var initializedList = [];
    var t = 0;
    while (t < plant_list.length) {
      tempInitialObj = {
        Hz_Ins: null,
        PR: null,
        Tilt_Ins: null,
        actual: null,
        capacity: null,
        contracted: null,
        kpi: null,
        kpi_actual: null,
        kpi_contracted: null,
        kpi_loss_perc: null,
        loss: null,
        plant_name: api.plant_dict[plant_list[t]],
        plant_id: plant_list[t],
      };
      t = t + 1;
      initializedList.push(tempInitialObj);
    }
    dispatchSummaryTablePastData({
      type: "GRAPHS_FETCH_INITIALIZE",
      payload: initializedList,
    });
    try {
      var axiosRequestArray = [];
      let temp_payload = {
        plant_id_list: [],
        start_date: startDate,
        end_date: endDate,
        mask: process.env.MASK,
      };
      var startTime = performance.now();
      var j = 0;
      var plantAggCount = 1;

      while (j < plant_list.length) {
        var tempPlantList = [];
        let indexList = [];
        if (j + plantAggCount >= plant_list.length) {
          for (var k = 0; k < plant_list.length - j; k++) {
            indexList.push(k);
          }
        } else {
          for (var k = 0; k < plantAggCount; k++) {
            indexList.push(k);
          }
        }

        indexList.map((idx) => {
          tempPlantList.push(plant_list[j + idx]);
        });

        temp_payload = { ...temp_payload, plant_id_list: tempPlantList };
        keysList.push(...tempPlantList);
        axiosRequestArray.push([`/api/summary/actual-energy`, temp_payload]);
        axiosRequestArray.push([
          `/api/summary/contracted-energy`,
          temp_payload,
        ]);
        axiosRequestArray.push([`/api/summary/ins`, temp_payload]);
        axiosRequestArray.push([`/api/summary/plant-capacity`, temp_payload]);
        axiosRequestArray.push([`/api/summary/kpi`, temp_payload]);
        axiosRequestArray.push([`/api/summary/result-pr`, temp_payload]);
        j = j + plantAggCount;
      }

      var endTime = performance.now();
      var beginTime = performance.now();
      for (var j = 0; j < plant_list.length; j++) {
        const payload = {
          plant_id_list: [plant_list[j]],
          start_date: startDate,
          end_date: endDate,
        };

        beginTime = performance.now();
        await axios
          .all([
            axios.post(`/api/summary/actual-energy`, payload),
            axios.post(`/api/summary/contracted-energy`, payload),
            axios.post(`/api/summary/ins`, payload),
            axios.post(`/api/summary/plant-capacity`, payload),
            axios.post(`/api/summary/kpi`, payload),
            axios.post(`/api/summary/result-pr`, payload),
          ])
          .then(
            axios.spread(
              (
                result_actual,
                result_contracted,
                result_ins,
                result_capacity,
                result_kpi,
                result_pr
              ) => {
                var loss = 0;
                let val1 = Object.values(result_actual.data.data)[0];
                let val2 = Object.values(result_contracted.data.data)[0];
                try {
                  loss = 100 * (1 - val1.actual / val2.contracted).toFixed(2);
                } catch {
                  loss = "-";
                }
                if (
                  val1 == null ||
                  val2 == null ||
                  val1.actual == null ||
                  val2.contracted == null
                ) {
                  loss = "-";
                }
                let outputLoss =
                  typeof loss == "number" ? loss.toFixed(2) : "-";

                merged = {
                  ...Object.values(result_actual.data.data)[0],
                  ...Object.values(result_contracted.data.data)[0],
                  ...Object.values(result_ins.data.data)[0],
                  ...Object.values(result_capacity.data.data)[0],
                  ...Object.values(result_pr.data.data)[0],
                  ...{ loss: outputLoss },
                  ...{ plant_id: plant_list[j]},
                  ...Object.values(result_kpi.data.data)[0],
                };
                initializedList[j] = merged;
                mergedList = [...mergedList, ...[merged]];
                endTime = performance.now();
                dispatchSummaryTablePastData({
                  type: "GRAPHS_FETCH_SUCCESS",
                  payload: initializedList,
                });
              }
            )
          )
          .catch(function (error) {
            merged = {
              Hz_Ins: null,
              PR: null,
              Tilt_Ins: null,
              actual: null,
              capacity: null,
              contracted: null,
              kpi: null,
              kpi_actual: null,
              kpi_contracted: null,
              kpi_loss_perc: null,
              loss: null,
              plant_name: api.plant_dict[plant_list[j]],
              plant_id: plant_list[j]
            };
            mergedList = [...mergedList, ...[merged]];
          });
      }
    } catch (error) {
      dispatchSummaryTablePastData({ type: "GRAPHS_FETCH_FAILURE" });
    }
  }, [startDate, endDate]);

  React.useEffect(() => {
    //handleSummaryPastData();
  }, [handleSummaryPastData]);

  const handleSummaryData = React.useCallback(async () => {
    
    dispatchDashboardtData({ type: "DATA_FETCH_INIT" });
    try {
      const plant_list = {
        ...treeObj,
        nodes: treeObj["nodes"].filter((value) =>
          session.user.plants["USER"].includes(value["plant_id"])
        ),
      }["nodes"].map((item) => item["plant_id"]);
      const payload = {
        plant_id_list: plant_list,
        start_date: startDate,
        end_date: endDate,
      };
      const result = await axios.post(`/api/Dashboard/card`, payload);
      console.log("result Data", result.data.data)
      dispatchDashboardtData({
        type: "DATA_FETCH_SUCCESS",
        payload: result.data.data,
      });
    } catch (e) {
      dispatchDashboardtData({ type: "DATA_FETCH_FAILURE" });
    }
  }, [startDate, endDate]);

  React.useEffect(() => {
    handleSummaryData();
  }, [handleSummaryData]);

// This piece of code was used for testing purposes.

  // const updatedData = dashboardData.data.data.filter(
  //   (item) => item["PLANT NAME"] !== "408NE_THAI"
  //   );
    
  //   console.log("DashBoard Data", updatedData)

  return (
    <>
      <Row>
        <Col xl="7">
          <>
            <Row
              className="carddivportfolio carddivportfolio1"
              style={{
                maxHeight: "calc(100vh - 15rem)",
                overflow: "auto",
                paddingTop: "0.6rem",
              }}
            >
              {dashboardData.isLoading ?  <div style={{ position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)"}}>
          <BarLoader loading={dashboardData.isLoading } css={override} size={150} />
        </div> : dashboardData.data.data.map((item) => (

                <div className="col-md-3 pl-2 pr-2" key={item["PLANT NAME"]}>
                  <Card
                    className="card-stats"
                    style={{ padding: "0rem", margin: "0rem 0rem 1rem 0rem" }}
                  >
                    <CardBody className="mapcard">
                      <i
                        className="fa fa-info iconbase"
                        id={item["PLANT NAME"]}
                      ></i>
                       {/* <UncontrolledTooltip
                        delay={0}
                        html="true"
                        placement="bottom"
                        target={item["PLANT NAME"]}
                      
                      >
                        <PlantMetaDatageo
                          plantname={item["PLANT NAME"]}
                          parentName="portfolioPage"
                        />
                      </UncontrolledTooltip>  */}
                      <Row>
                        <div className="col">
                          {item.color == 0 ? (
                            <CardTitle
                              tag="h5"
                              className="text-uppercase text-muted mb-0 no-list-bullet orange"
                            >
                              <Link href="/overview">
                                <a
                                  onClick={() => {
                                    var clickObj = plantLevelMeter(
                                      item["PLANT NAME"]
                                    );
                                    api.onMeterChange(
                                      clickObj.meter_id,
                                      clickObj.plant_id
                                    );
                                  }}
                                  style={{
                                    width: "calc(100% - 10px)",
                                    display: "block",
                                  }}
                                >
                                  {item["PLANT NAME"]}
                                </a>
                              </Link>
                            </CardTitle>
                          ) : item.color > 0  ? (
                            <CardTitle
                              tag="h5"
                              className="text-uppercase text-muted mb-0 no-list-bullet green"
                            >
                              <Link href="/overview">
                                <a
                                  onClick={() => {
                                    var clickObj = plantLevelMeter(
                                      item["PLANT NAME"]
                                    );
                                    api.onMeterChange(
                                      clickObj.meter_id,
                                      clickObj.plant_id
                                    );
                                  }}
                                  style={{
                                    width: "calc(100% - 10px)",
                                    display: "block",
                                  }}
                                >
                                  {item["PLANT NAME"]}
                                </a>
                              </Link>
                            </CardTitle>
                          ) : (
                            <CardTitle
                              tag="h5"
                              className="text-uppercase text-muted mb-0 no-list-bullet red"
                            >
                              <Link href="/overview">
                                <a
                                  onClick={() => {
                                    var clickObj = plantLevelMeter(
                                      item.plant_name
                                    );
                                    api.onMeterChange(
                                      clickObj.meter_id,
                                      clickObj.plant_id
                                    );
                                  }}
                                  style={{
                                    width: "calc(100% - 10px)",
                                    display: "block",
                                  }}
                                >
                                 {item["PLANT NAME"]}
                                </a>
                              </Link>
                            </CardTitle>
                          )}
                          <ul
                            className="plantlistcardul"
                            style={{ marginBottom: "0px" }}
                          >
                            <li className="plantlistcard">
                              <div className="plantlistcarddetail">
                                <span className="keylabel"> Actual Energy</span>
                                <span className="keyval">
                                  {item["ACTUAL ENERGY (MWH)"] == null ? "-" : item["ACTUAL ENERGY (MWH)"]}
                                </span>
                              </div>
                            </li>
                            <li className="plantlistcard">
                              <div className="plantlistcarddetail">
                                <span className="keylabel"> Capacity</span>
                                <span className="keyval">{item["CAPACITY (MWp)"]}</span>
                              </div>
                            </li>
                            <li className="plantlistcard">
                              <div className="plantlistcarddetail">
                                <span className="keylabel"> GHI/GII</span>
                                <span className="keyval">
                                  {item["GHI/GII"]}
                                </span>
                              </div>
                            </li>
                            <li
                              className="plantlistcard"
                              style={{ borderBottom: "0px" }}
                            >
                              <div className="plantlistcarddetail">
                                <span className="keylabel"> PR</span>
                                <span className="keyval">{item["PR (%)"]}</span>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </Row>
                    </CardBody>
                  </Card>
                </div>
              ))}
            </Row>
          </>
        </Col>
        <Col xl="5">
          <div
            style={{
              fontSize: "12px",
              textAlign: "right",
              position: "absolute",
              zIndex: 9,
              top: "3px",
              marginLeft: "3px",
              background: "#fff",
            }}
          >
            <span className="pl-1 pr-1">
              <i className="fa fa-circle  greenval"></i> Excess
            </span>
            <span className="pl-1 pr-1">
              {" "}
              <i className="fa fa-circle  orangeval"></i> Short Fall
            </span>
            <span className="pl-1 pr-1">
              <i className="fa fa-circle  redval"></i> No Data
            </span>
          </div>
          <div
            style={{ height: "80vh", width: "100%" }}
            className="carddivportfolio"
          >
            {/*{<GoogleMapReact*/}
            {/*  bootstrapURLKeys={{*/}
            {/*    key: defaultProps.key,*/}
            {/*  }}*/}
            {/*  defaultCenter={defaultProps.center}*/}
            {/*  defaultZoom={defaultProps.zoom}*/}
            {/*>*/}
            {/*  {dashboardData.data.data.map((item) => {*/}
            {/*    return <GeoLocations*/}
            {/*      key={item["PLANT NAME"]}*/}
            {/*      lat={item["coord"][0]}*/}
            {/*      lng={item["coord"][1]}*/}
            {/*      data={item}*/}
            {/*    />*/}
            {/*  })}*/}
            {/*</GoogleMapReact>}*/}

            <ReactECharts
                option={getOption()}
                style={{height: "80vh", width: "100%"}}
            />          </div>
        </Col>
      </Row>
      <style jsx global>
        {`
          .markertext {
            display: none;
          }
          .marmarker {
            cursor: pointer;
          }
          .marmarker:hover .markertext {
            display: block;
            font-size: 12px;
            font-weight: bold;
            position: absolute;
            margin-top: -36px;
            background: #f4f6f6;
            color: #000;
            padding: 2px 4px;
            border-radius: 3px;
          }
          .mapcard {
            padding: 0.5rem 0.5rem !important;
          }
          .filterview {
            max-width: 16rem;
            position: absolute;
            right: 0px;
            top: 4rem;
            width: 100%;
            background: #fff;
            z-index: 9;
            box-shadow: 0px 0px 10px #00000040;
            border-radius: 0.4rem;
            padding: 1.7rem 0.7rem;
            max-height: 75vh;
            overflow: auto;
          }
          #displayfilter {
            display: none;
          }
          #displayfilter:checked ~ .remove-check {
            display: none;
          }
          #displayfilter:checked ~ .filterview {
            display: block;
          }
          .filterview {
            display: none;
          }
          .tooltip-inner {
            background: #fff;
            max-width: 350px;
            color: #000;
            text-align: left;
            font-size: 0.8rem;
          }
          .tooltip.show {
            opacity: 1;
            box-shadow: 0px 0px 10px #00000040;
            background: #fff;
          }
          .orange {
            background: orange;
            color: #fff !important;
            padding-bottom: 2px;
          }
          .red {
            background: red;
            color: #fff !important;
            padding-bottom: 2px;
          }
          .green {
            background: green;
            color: #fff !important;
            padding-bottom: 2px;
          }
          .iconbase {
            color: #fff;
            right: 12px;
            top: 12px;
          }
          .card-title:before {
            display: none;
          }
        `}
      </style>
    </>
  );
}
