import React from "react";
import Login from "pages/index";
import AdminFooter from "components/Footers/AdminFooter.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import CustomNavbar from "components/Navbars/customNavbar";
import useAppContext from "context/state";
import classnames from "classnames";
import { useSession } from "next-auth/client";
import { useRouter, withRouter } from "next/router";
import { Collapse, Navbar, NavItem, Nav, Spinner, Button } from "reactstrap";
import full_tree from "plant_variables/full_tree.json";
import trim_tree from "plant_variables/trim_tree.json";
import Link from "next/link";
import TopRightNav from "../components/Navbars/TopRightNav";

function Admin(props) {
  const router = useRouter();
  const [session, loading] = useSession();
  if (session) {
    const api = useAppContext();
    const [sidenavOpen, setSidenavOpen] = React.useState(true);
    const [logoUrl, setLogoUrl] = React.useState("/" + api.logoUrl.data);
    const childrenWithProps = React.Children.map(
      props.children,
      (child, index) => {
        return React.cloneElement(child, {
          index,
          showGlobal: api.showGlobal,
          showSV: api.showSV,
          showEV: api.showEV,
          showST: api.showST,
          show_unnormalized: api.show_unnormalized,
          startDate:
            router.query.startDate != undefined
              ? router.query.startDate
              : api.startDate,
          endDate:
            router.query.endDate != undefined
              ? router.query.endDate
              : api.endDate,
          plant_id: api.plant_id,
          currentMeter: api.currentMeter,
          onDateChange: api.onDateChange,
          onMeterChange: api.onMeterChange,
          meterName: api.meterName,
          handleMeterName: api.handleMeterName,
          financeDays: api.financeDays,
          plantDict: api.plant_dict,
          plantIdDict: api.plant_id_dict,
          noiseTickets: api.noiseTickets,
          closeTickets: api.closeTickets,
          handleNoiseTicketChange: api.handleNoiseTicketChange,
          handleCloseTicketChange: api.handleCloseTicketChange,
          treeObj: {
            ...api.treeObj,
            nodes: api.treeObj["nodes"].filter((value) =>
              session.user.plants["USER"].includes(value["plant_id"]),
            ),
          },
          fullTreeObj: {
            ...api.fullTreeObj,
            nodes: api.fullTreeObj["nodes"].filter((value) =>
              session.user.plants["USER"].includes(value["plant_id"]),
            ),
          },
        });
      },
    );

    const toggleSidenav = (e) => {
      if (document.body.classList.contains("g-sidenav-pinned")) {
        document.body.classList.remove("g-sidenav-pinned");
        document.body.classList.add("g-sidenav-hidden");
      } else {
        document.body.classList.add("g-sidenav-pinned");
        document.body.classList.remove("g-sidenav-hidden");
      }
      setSidenavOpen(!sidenavOpen);
    };
    const getNavbarTheme = () => {
      return props.router.pathname.indexOf("admin/alternative-dashboard") === -1
        ? "dark"
        : "light";
    };

    // console.log(">>>>>>",router.query.start_date,router.query.end_date)

    return (
      <>
        {[
          "/meter-data",
          "/meter-readings/[slug]",
          "/meter-heatmaps/[slug]",
          "/sibling-heatmap/[slug]",
          "/meter-data-new/[slug]",
          "/analytics/scatter-plot",
        ].includes(props.router.pathname) ? (
          <Sidebar
            toggleSidenav={toggleSidenav}
            sidenavOpen={sidenavOpen}
            onMeterChange={api.onMeterChange}
            tree={{
              ...full_tree,
              nodes: full_tree["nodes"].filter((value) =>
                session.user.plants["USER"].includes(value["plant_id"]),
              ),
            }}
            handleMeterName={api.handleMeterName}
            plant_id={api.plant_id}
            currentMeter={api.currentMeter}
            onDateChange={api.onDateChange}
            setPlantAuditList={api.setPlantAuditList}
            changeAuditID={api.changeAuditID}
          />
        ) : [
            "/",
            "/summary",
            "/geoMap",
            "/reports",
            "/events/evidence",
            "/manage/plant-config",
            "/dashboard/overview-v2",
            "/reports/history/[taskid]",
            "/plantAvailability",
            "/portfolio",
            "/availability/[slug]",
            "/landing-page",
            "/landing-page-v2",
            "/solar-power-schedule",
            "/solar-power-billing",
            "/landing-page-v3",
            "/tms-report",
            "/rules",
            "/rules/edit",
            "/tickets/[slug]",
            "/events/detail",
            "/audit-tickets/[slug]",
            "/portfolio-report",
            "/manual-dgr",
            "/overviewBatteries",
            "/inventory",
            "/inventory/trail",
            "/events/timeline",
            // "/events/[slug]",
            "/meter-blacklist",
            "/tickets-page-v2",
            "/analytics/peer-heatmap-plots",
            "/reports/kpi",
            "/inventory-v2",
            "/inventory-v2/manage-inventory",
            "/inventory-v2/manage-work-order",
            "/inventory-v2/manage-inventory/[id]",
            "/inventory-v2/manage-inventory/add",
            "/inventory-v2/manage-work-order/create",
            "/inventory-v2/manage-work-order/[id]",
          ].includes(props.router.pathname) ? null : (
          // <Sidebar
          // toggleSidenav={toggleSidenav}
          // sidenavOpen={sidenavOpen}
          //  onMeterChange={api.onMeterChange}
          // tree={{
          //    ...trim_tree,
          //    nodes: trim_tree["nodes"].filter((value) =>
          //     session.user.plants["USER"].includes(value["plant_id"])
          //   ),
          // }}
          //  user={session.user}
          //  handleMeterName={api.handleMeterName}
          //  plant_id={api.plant_id}
          // currentMeter={api.currentMeter}
          // onDateChange={api.onDateChange}
          //  setPlantAuditList={api.setPlantAuditList}
          // changeAuditID={api.changeAuditID}
          // />
          <Sidebar
            toggleSidenav={toggleSidenav}
            sidenavOpen={sidenavOpen}
            onMeterChange={api.onMeterChange}
            tree={{
              ...full_tree,
              nodes: full_tree["nodes"].filter((value) =>
                session.user.plants["USER"].includes(value["plant_id"]),
              ),
            }}
            handleMeterName={api.handleMeterName}
            plant_id={api.plant_id}
            currentMeter={api.currentMeter}
            onDateChange={api.onDateChange}
            setPlantAuditList={api.setPlantAuditList}
            changeAuditID={api.changeAuditID}
          />
        )}
        <div className="main-content">
          <Navbar
            className={classnames(
              "top-nav navbar-expand border-bottom top-nav d-flex justify-content-between align-items-center bg-white",
              { "navbar-white": getNavbarTheme() === "dark" },
              { "navbar-light bg-secondary": getNavbarTheme() === "light" },
            )}
          >
            <div className="d-flex align-items-center ">
              <Collapse navbar isOpen={true}>
                <Nav className="align-items-center" navbar>
                  <NavItem className="d-xl-none">
                    <div
                      className={classnames(
                        "ml-md-2 mr-2 p-1 sidenav-toggler text-clr",
                        { active: sidenavOpen },
                        { "sidenav-toggler-dark": getNavbarTheme() === "dark" },
                      )}
                      onClick={toggleSidenav}
                    >
                      <svg className="ic_">
                        <use xlinkHref="#ic_plant" />
                      </svg>
                    </div>
                  </NavItem>
                </Nav>
              </Collapse>
              <img
                src={logoUrl}
                onClick={() => {
                  router.push(`${process.env.start_url}`);
                }}
                className="cmp-logo mr-2"
                style={{ width: "8rem", height: "3rem" }}
              />
              <div className="container-fluid">
                <CustomNavbar
                  plant_id={api.plant_id}
                  currentMeter={api.currentMeter}
                  startDate={
                    router.query.startDate != undefined
                      ? router.query.startDate
                      : api.startDate
                  }
                  endDate={
                    router.query.endDate != undefined
                      ? router.query.endDate
                      : api.endDate
                  }
                  financeDays={api.financeDays}
                  showGlobal={api.showGlobal}
                  showSV={api.showSV}
                  showEV={api.showEV}
                  showST={api.showST}
                  show_unnormalized={api.show_unnormalized}
                />
              </div>
            </div>
            <div>
              <TopRightNav
                startDate={
                  router.query.start_date != undefined
                    ? router.query.start_date
                    : api.startDate
                }
                endDate={
                  router.query.end_date != undefined
                    ? router.query.end_date
                    : api.endDate
                }
              />
            </div>
          </Navbar>

          {childrenWithProps}
          <AdminFooter />
        </div>
        {sidenavOpen ? (
          <div className="backdrop d-xl-none" onClick={toggleSidenav} />
        ) : null}
      </>
    );
  }
  if (loading) return null;

  if (!loading && !session) return <p>Access Denied</p>;

  return (
    <>
      <Login />
    </>
  );
}

export default withRouter(Admin);
