import React from "react";
import { defaultDataReducer } from "components/Reducers/dataReducers";
import { Card, CardHeader, CardBody, Container, Row, Col } from "reactstrap";
import axios from "axios";
import useAppContext from "context/state";

const DEMOFLAG = false;

export function PlantMetaData({ plantname, parentName }) {
  const [metaData, dispatchMetaData] = React.useReducer(defaultDataReducer, {
    data: { data: {} },
    isLoading: false,
    isError: false,
  });
  const api = useAppContext();
  const handleMetaData = React.useCallback(async () => {
    dispatchMetaData({ type: "DATA_FETCH_INIT" });
    try {
      const payload = {
        plant_id: api.plant_id_dict[plantname],
      };
      const result = await axios.post(`/api/summary/plantRelatedData`, payload);
      dispatchMetaData({
        type: "DATA_FETCH_SUCCESS",
        payload: { data: result.data.data },
      });
    } catch {
      dispatchMetaData({ type: "DATA_FETCH_FAILUR" });
    }
  }, []);

  React.useEffect(() => {
    handleMetaData();
  }, []);

  return (
    <>
      <div className="row">
        <p className="col-md-12 pt-2 plantnametext">Plant Name: {plantname}</p>
        {metaData.isLoading ? (
          <p>Is Loading....</p>
        ) : (
          Object.entries(metaData.data.data).map(([key, value]) => {
            // Pretty straightforward - use key for the key and value for the value.
            // Just to clarify: unlike object destructuring, the parameter names don't matter here.
            return (
              <>
                {parentName == "summaryPage" ? (
                  <ul className="col-md-6">
                    <li className="no-list-bullet">{key}</li>
                    {Object.entries(value).map(([key, value]) => {
                      // Pretty straightforward - use key for the key and value for the value.
                      // Just to clarify: unlike object destructuring, the parameter names don't matter here.
                      return (
                        <ul className="pl-4">
                          <li>
                            <div className="row">
                              <div className="col-7">{key}</div>
                              <div className="col-5">
                                :{" "}
                                {DEMOFLAG == true &&
                                  ["Lead Name", "Lead Phone", "Client"].includes(
                                    key
                                  ) ? (
                                  <>-</>
                                ) : (
                                  value
                                )}
                              </div>
                            </div>
                          </li>
                        </ul>
                      );
                    })}
                  </ul>
                ) : (
                  <>
                    {["O&M", "Other"].includes(key) ? (
                      <ul className="col-md-6">
                        <li className="no-list-bullet">{key}</li>
                        {Object.entries(value).map(([key, value]) => {
                          // Pretty straightforward - use key for the key and value for the value.
                          // Just to clarify: unlike object destructuring, the parameter names don't matter here.
                          return (
                            <>
                              {[
                                "Lead Name",
                                "Performance Parameter",
                                "Contractual Guarantee Param",
                                "Commissioning date",
                                "Contract Start Date",
                                "Contract Start Month",
                                "Tenure"
                              ].includes(key) ? <ul className="pl-4">
                                <li>
                                  <div className="row">
                                    <div className="col-7">{key}</div>
                                    <div className="col-5">
                                      :{" "}
                                      {DEMOFLAG == true &&
                                        [
                                          "Lead Name",
                                          "Lead Phone",
                                          "Client",
                                        ].includes(key) ? (
                                        <>-</>
                                      ) : (
                                        value
                                      )}
                                    </div>
                                  </div>
                                </li>
                              </ul> : null}

                            </>
                          );
                        })}
                      </ul>
                    ) : null}
                  </>
                )}
              </>
            );
          })
        )}
      </div>
    </>
  );
}

export function PlantMetaDatageo({ plantname, parentName }) {
  const [metaData, dispatchMetaData] = React.useReducer(defaultDataReducer, {
    data: { data: {} },
    isLoading: false,
    isError: false,
  });
  const api = useAppContext();
  const handleMetaData = React.useCallback(async () => {
    dispatchMetaData({ type: "DATA_FETCH_INIT" });
    try {
      const payload = {
        plant_id: api.plant_id_dict[plantname],
      };
      const result = await axios.post(`/api/summary/plantRelatedData`, payload);
      dispatchMetaData({
        type: "DATA_FETCH_SUCCESS",
        payload: { data: result.data.data },
      });
    } catch {
      dispatchMetaData({ type: "DATA_FETCH_FAILUR" });
    }
  }, []);

  React.useEffect(() => {
    handleMetaData();
  }, []);

  return (
    <>
      <div className="row">
        {/* <p className="col-md-12 pt-2 plantnametext">Plant Name: {plantname}</p> */}
        {metaData.isLoading ? (
          <p>Is Loading....</p>
        ) : (
          Object.entries(metaData.data.data).map(([key, value]) => {
            // Pretty straightforward - use key for the key and value for the value.
            // Just to clarify: unlike object destructuring, the parameter names don't matter here.
            return (
              <>
                {parentName == "summaryPage" ? (
                  <ul className="col-md-12">
                    <li className="no-list-bullet">{key}</li>
                    {Object.entries(value).map(([key, value]) => {
                      // Pretty straightforward - use key for the key and value for the value.
                      // Just to clarify: unlike object destructuring, the parameter names don't matter here.
                      return (
                        <ul className="pl-4">
                          <li>
                            <div className="row">
                              <div className="col-7">{key}</div>
                              <div className="col-5">
                                :{" "}
                                {DEMOFLAG == true &&
                                  ["Lead Name", "Lead Phone", "Client"].includes(
                                    key
                                  ) ? (
                                  <>-</>
                                ) : (
                                  value
                                )}
                              </div>
                            </div>
                          </li>
                        </ul>
                      );
                    })}
                  </ul>
                ) : (
                  <>
                    {["O&M", "Other"].includes(key) ? (
                      <ul className="col-md-12">
                        <li className="no-list-bullet">{key}</li>
                        {Object.entries(value).map(([key, value]) => {
                          // Pretty straightforward - use key for the key and value for the value.
                          // Just to clarify: unlike object destructuring, the parameter names don't matter here.
                          return (
                            <>
                              {[
                                "Lead Name",
                                "Performance Parameter",
                                "Contractual Guarantee Param",
                                "Commissioning date",
                                "Contract Start Date",
                                "Contract Start Month",
                                "Tenure"
                              ].includes(key) ? <ul className="pl-4">
                                <li>
                                  <div className="row">
                                    <div className="col-7">{key}</div>
                                    <div className="col-5">
                                      :{" "}
                                      {DEMOFLAG == true &&
                                        [
                                          "Lead Name",
                                          "Lead Phone",
                                          "Client",
                                        ].includes(key) ? (
                                        <>-</>
                                      ) : (
                                        value
                                      )}
                                    </div>
                                  </div>
                                </li>
                              </ul> : null}

                            </>
                          );
                        })}
                      </ul>
                    ) : null}
                  </>
                )}
              </>
            );
          })
        )}
      </div>
    </>
  );
}

