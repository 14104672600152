import React from "react";
import { Calendar, utils } from "@hassanmojab/react-modern-calendar-datepicker";
import dynamic from "next/dynamic";
import useAppContext from "context/state";
import { defaultDataReducer } from "components/Reducers/dataReducers";
import axios from "axios";
import { calculateMaxMinDate } from "components/Utility/calendarDates";
import { util } from "echarts";

const DatePickerWithNoSSR = dynamic(
  () => import("@hassanmojab/react-modern-calendar-datepicker"),
  { ssr: false }
);

const renderCustomInput = ({ ref }) => (
  <>
    <button
      ref={ref} // necessary
      placeholder="Date"
      className="   btn-icon text-gray-dark border-0 backdrop-light " // a styling class
      style={{fontSize:"1.2em"}}
    >
      <i className="ni ni-calendar-grid-58" />
    </button>
  </>
);

const DatePickerCalendar = (props) => {
  const api = useAppContext();
  const [defaultData, dispatchDefaultData] = React.useReducer(
    defaultDataReducer,
    {
      data: [],
      isLoading: false,
      isError: false,
    }
  );
  const handleFetchDefaultData = React.useCallback(async () => {
    dispatchDefaultData({ type: "DATA_FETCH_INIT" });
    try {
      const payload = {
        plant_id_list: [parseInt(api.plant_id)],
      };
      const result = await axios.post(`/api/data-tag`, payload);
      dispatchDefaultData({
        type: "DATA_FETCH_SUCCESS",
        payload: result.data.data,
      });
    } catch (error) {
      dispatchDefaultData({ type: "DATA_FETCH_FAILURE" });
    }
  }, [api.plant_id]);

  React.useEffect(() => {
    handleFetchDefaultData();
  }, [handleFetchDefaultData]);

  return (
    <>
      {props.parenName == "datePicker" ? (
        <DatePickerWithNoSSR
          value={props.day}
          onChange={props.dayHandler}
          inputPlaceholder="Date"
          shouldHighlightWeekends
          calendarClassName="responsive-calendar"
          renderInput={renderCustomInput}
          colorPrimary="#007bff"
          colorPrimaryLight="#D5E9FF"
          maximumDate={
            defaultData.isLoading
              ? null
              : calculateMaxMinDate(defaultData.data, api.plant_id)[1]
          }
          
        />
      ) : props.parenName == "manualTicket" ? (
        <DatePickerWithNoSSR
          value={props.day}
          onChange={props.dayHandler}
          shouldHighlightWeekends
          colorPrimary="#007bff"
          colorPrimaryLight="#D5E9FF"
          slideAnimationDuration="1s"
          maximumDate={
            defaultData.isLoading
              ? null
              : calculateMaxMinDate(defaultData.data, api.plant_id)[1]
          }
          inputPlaceholder="Select issue date"
        />
      ) : props.parenName == "preventiveSchedule" ? (
        <DatePickerWithNoSSR
          value={props.day}
          onChange={props.dayHandler}
          shouldHighlightWeekends
          colorPrimary="#007bff"
          colorPrimaryLight="#D5E9FF"
          slideAnimationDuration="1s"
          inputPlaceholder="Select Start Date"
        />
      ) : (
        <Calendar
          value={props.day}
          onChange={props.dayHandler}
          calendarClassName="responsive-calendar"
          shouldHighlightWeekends
          colorPrimary="#007bff"
          colorPrimaryLight="#D5E9FF"
          slideAnimationDuration="1s"
          maximumDate={
            defaultData.isLoading
              ? null
              : calculateMaxMinDate(defaultData.data, api.plant_id)[1]
          }
          
        />
      )}
    </>
  );
};

export default DatePickerCalendar;
