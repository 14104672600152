import React from "react";
import Link from "next/link";
import classnames from "classnames";
import { PropTypes } from "prop-types";
import PerfectScrollbar from "react-perfect-scrollbar";
import TreeMenu from "react-simple-tree-menu";
import { useRouter } from "next/router";
import trim_tree from "plant_variables/trim_tree.json";
import useAppContext from "context/state";

import {
  Collapse,
  NavbarBrand,
  Navbar,
  Nav,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Input,
  ListGroupItem,
  ListGroup,
  Row,
  Col,
} from "reactstrap";
import { ChevronDown, ChevronLeft, ChevronRight } from "lucide-react";

const ToggleIcon = ({ on }) => (
  <span>{on ? <ChevronDown /> : <ChevronRight />}</span>
);

const ListItem = ({
  level = 0,
  hasNodes,
  isOpen,
  label,
  searchTerm,
  openNodes,
  toggleNode,
  matchSearch,
  focused,
  ...props
}) => (
  <ListGroupItem
    className="SideBarListItems"
    {...props}
    style={{
      paddingLeft: ICON_SIZE + level * LEVEL_SPACE,
      cursor: "pointer",
      position: "relative",
      color: "white",
      fontSize: "0.875rem",
      width: "98%",
      margin: "auto",
      display: "grid",
    }}
  >
    <Row style={{ marginRight: "0px", marginLeft: "0px" }}>
      <Col style={{ display: "flex" }}>
        <span style={{ flex: 2 }}>{label}</span>

        {hasNodes && (
          <span
            style={{ display: "inline-block", textAlign: "right", flex: 1 }}
            onClick={(e) => {
              hasNodes && toggleNode && toggleNode();
              e.stopPropagation();
            }}
          >
            <ToggleIcon on={isOpen} />
          </span>
        )}
      </Col>
    </Row>
  </ListGroupItem>
);

const plantIDClickList = trim_tree["nodes"].map(
  (item) => item.plant_id.toString() + "_" + item.meter_id.toString(),
);
const DEMODATE = "15 Aug 2021";
const demoFlag = false;
const ICON_SIZE = 8;
const LEVEL_SPACE = 16;

function Sidebar({
  toggleSidenav,
  sidenavOpen,
  onMeterChange,
  logo,
  rtlActive,
  tree,
  handleMeterName,
  plant_id,
  currentMeter,
  onDateChange,
  setPlantAuditList,
  changeAuditID,
}) {
  const [windowWidth, setWindowWidth] = React.useState(0);
  const [navigatorPlatform, setNavigatorPlatform] = React.useState("");
  const router = useRouter();
  const DEFAULT_PLANT_ID = trim_tree["nodes"][0]["plant_id"];
  const DEFAULT_METER_ID = trim_tree["nodes"][0]["parent_id"];
  const slug = router.query;
  const createOpenNodes = (nodesActualArray) => {
    const nodesArray = nodesActualArray;
    let routeArray = [nodesArray[0]];
    let temp = nodesArray[0];
    for (var k = 1; k < nodesArray.length; k++) {
      temp = temp + "/" + nodesArray[k];
      routeArray.push(temp);
    }
    return routeArray;
  };

  const getActiveNodeKey = (tree, plantId, meterId) => {
    let result = createOpenNodes(
      getOpenNodesByMeterId(tree, slug.plant_id, slug.meter_id),
    );
    return result[result.length - 1] + "/" + plantId + "_" + meterId;
  };

  const getOpenNodesByMeterId = (tree, plantId, meterId) => {
    let opentabs = [];
    let leafNode = findMeterForPlant(tree, plantId, meterId);
    if (leafNode) {
      while (leafNode && leafNode.parent_id != 0) {
        leafNode = findMeterForPlant(tree, plantId, leafNode.parent_id);
        if (leafNode) {
          opentabs.push(leafNode["key"]);
        }
      }
    }
    opentabs.push("0ALL");
    return opentabs.reverse();
  };

  const findMeterForPlant = (entireObj, plant_id, meter_id) => {
    let foundObj;
    JSON.stringify(entireObj, (_, nestedValue) => {
      if (
        nestedValue &&
        nestedValue["meter_id"] == meter_id &&
        nestedValue["plant_id"] == plant_id
      ) {
        foundObj = nestedValue;
      }
      return nestedValue;
    });
    return foundObj;
  };

  const api = useAppContext();
  const [opennodes, setOpenNodes] = React.useState(
    slug.meter_id != undefined && slug.plant_id != undefined
      ? createOpenNodes(
          getOpenNodesByMeterId(tree, slug.plant_id, slug.meter_id),
        )
      : ["0ALL"],
  );
  //
  const [activeKey, setActiveKey] = React.useState(
    slug.meter_id != undefined && slug.plant_id != undefined
      ? getActiveNodeKey(tree, slug.plant_id, slug.meter_id)
      : ["0ALL"],
  );

  // const [opennodes, setOpenNodes] = React.useState(['0ALL']);

  // const [activeKey, setActiveKey] = React.useState(['0ALL']);

  React.useEffect(() => {
    setWindowWidth(window.innerWidth);
    setNavigatorPlatform(navigator.platform);
  }, []);

  React.useEffect(() => {
    setOpenNodes(
      createOpenNodes(getOpenNodesByMeterId(tree, plant_id, slug.meter_id)),
    );
  }, [slug.meter_id, slug.meter_id]);

  const onMouseEnterSidenav = () => {
    if (!document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.add("g-sidenav-show");
    }
  };
  // makes the sidenav mini on hover (actually when mouse leaves from it)
  const onMouseLeaveSidenav = () => {
    if (!document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.remove("g-sidenav-show");
    }
  };

  const closeSidenav = () => {
    if (windowWidth < 1200) {
      toggleSidenav();
    }
  };

  const scrollBarInner = (
    <div className="scrollbar-inner">
      <div className="sidenav-header d-flex align-items-center">
        {logo && logo.innerLink ? (
          <Link href={logo.innerLink}>
            <span>
              <NavbarBrand href="#pablo">
                <img
                  alt={logo.imgAlt}
                  className="navbar-brand-img"
                  src={logo.imgSrc}
                />
              </NavbarBrand>
            </span>
          </Link>
        ) : null}
        {logo && logo.outterLink ? (
          <NavbarBrand href={logo.outterLink} target="_blank">
            <img
              alt={logo.imgAlt}
              className="navbar-brand-img"
              src={logo.imgSrc}
            />
          </NavbarBrand>
        ) : null}
        <div className="ml-auto">
          <div
            className={classnames("sidenav-toggler d-none d-xl-block", {
              active: sidenavOpen,
            })}
            onClick={toggleSidenav}
          >
            <svg className="ic_ text-white">
              <use xlinkHref="#ic_plant" />
            </svg>
          </div>
        </div>
      </div>
      <div className="navbar-inner">
        <Collapse navbar isOpen={true} width={"100%"}>
          <Nav navbar>
            <TreeMenu
              data={[tree]}
              initialOpenNodes={opennodes}
              //initialOpenNodes={createOpenNodes(getOpenNodesByMeterId(tree, plant_id, currentMeter))}
              initialActiveKey={activeKey}
              resetOpenNodesOnDataUpdate={false}
              onClickItem={({ key, label, ...props }) => {
                if (props.plant_id === 0) {
                  router.push({
                    pathname: router.pathname,
                    query: {
                      ...router.query,
                      plant_id: DEFAULT_PLANT_ID,
                      meter_id: DEFAULT_METER_ID,
                    },
                  });
                  onMeterChange(DEFAULT_METER_ID, DEFAULT_PLANT_ID);
                } else {
                  var start_d = new Date();
                  start_d.setHours(0, 0, 0, 0);
                  var end_d = new Date();
                  end_d.setHours(23, 55, 59, 0);

                  onMeterChange(props.meter_id, props.plant_id);
                  handleMeterName(props.node_name);
                  const selectedPlant = api.dynamic_audit_data[
                    "audit_list"
                  ].filter((item) => item["plant_id"] == props.plant_id);
                  const Id =
                    selectedPlant.length != 0 &&
                    selectedPlant[0]["data"].length >= 1
                      ? selectedPlant[0]["data"][0]["audit_id"]
                      : 0;

                  if (
                    selectedPlant.length != 0 &&
                    selectedPlant[0]["data"].length != 0
                  ) {
                    router.push({
                      pathname: router.pathname,
                      query: {
                        ...router.query,
                        plant_id: props.plant_id,
                        meter_id: props.meter_id,
                        audit_id: Id,
                      },
                    });
                    setPlantAuditList(selectedPlant);
                    changeAuditID(Id);
                  } else {
                    router.push({
                      pathname: router.pathname,
                      query: {
                        ...router.query,
                        plant_id: props.plant_id,
                        meter_id: props.meter_id,
                        audit_id: 0,
                      },
                    });
                  }
                }
              }}
            >
              {({ search, items }) => (
                <>
                  {/* <Input
                    onChange={(e) => search(e.target.value)}
                    placeholder="Type and search"
                  /> */}
                  <ListGroup>
                    {items.map(({ reset, ...props }) => (
                      <ListItem {...props} />
                    ))}
                  </ListGroup>
                </>
              )}
            </TreeMenu>
          </Nav>
          <hr className="my-3" />
        </Collapse>
        {/* <UncontrolledDropdown>
          <DropdownToggle
            caret
            color="secondary"
            id="dropdownMenuButton"
            type="button"
            style={{ width: "100%" }}
          >
            Sort By
          </DropdownToggle>
          <DropdownMenu aria-labelledby="dropdownMenuButton">
            <DropdownItem href="#pablo" onClick={(e) => e.preventDefault()}>
              Alpha
            </DropdownItem>
            <DropdownItem href="#pablo" onClick={(e) => e.preventDefault()}>
              Low Perf
            </DropdownItem>
            <DropdownItem href="#pablo" onClick={(e) => e.preventDefault()}>
              High Perf
            </DropdownItem>
            <DropdownItem href="#pablo" onClick={(e) => e.preventDefault()}>
              Daily Loss
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown> */}
      </div>
    </div>
  );
  return (
    <Navbar
      className={
        "sidenav navbar-vertical navbar-expand-xs navbar-light bg-white " +
        (rtlActive ? "" : "fixed-left")
      }
      onMouseEnter={onMouseEnterSidenav}
      onMouseLeave={onMouseLeaveSidenav}
    >
      {navigatorPlatform && navigatorPlatform.indexOf("Win") > -1 ? (
        <PerfectScrollbar>{scrollBarInner}</PerfectScrollbar>
      ) : (
        scrollBarInner
      )}
    </Navbar>
  );
}

Sidebar.defaultProps = {
  toggleSidenav: () => {},
  sidenavOpen: false,
};

Sidebar.propTypes = {
  // function used to make sidenav mini or normal
  toggleSidenav: PropTypes.func,
  // prop to know if the sidenav is mini or normal
  sidenavOpen: PropTypes.bool,
  // links that will be displayed inside the component
};

export default Sidebar;
